import { HtmlForm } from './HtmlForm'

export class MarketoForm extends HtmlForm {
  marketoForm: any
  marketoButton: any
  marketoButtonTitle: any
  addSubmitHandler() {
    this.instrumentMarketoForm(this.formId)
  }

  instrumentMarketoForm(marketoFormId: string | number) {
    const form = window.MktoForms2?.getForm(marketoFormId) || window.MktoForms2?.allForms()[0]
    if (form && form.getId()) {
      this.marketoForm = form
      this.marketoButton = this.marketoForm.getFormElem().find('.mktoButton')
      this.marketoButtonTitle = this.marketoButton.html()
      form.onSuccess((data: any) => {
        this.showCalendar(this, data)
        return false
      })
    } else {
      setTimeout(() => this.instrumentMarketoForm(marketoFormId), 500)
    }
  }

  get formOptions() {
    return {
      fromLegacy: true,
      formId: this.options.formId,
      debug: this.options.debug === true,
      domElement: this.options.domElement,
      domain: this.options.domain,
      router: this.options.router,
      locale: this.options.locale,
      accountId: this.options.accountId,
      type: this.options.type,
      ownerId: this.options.ownerId,
      skipOwnershipLogic: this.options.skipOwnershipLogic,
      webHook: this.options.webHook,
      disableRelation: this.options.disableRelation,
      skipFormFallbackUponAccountDQ: this.options.skipFormFallbackUponAccountDQ,
      map: Boolean(this.options.map),
    }
  }

  onClose(hasBooked: boolean) {
    super.onClose(hasBooked)
    this.marketoForm.submittable(true)
    this.marketoButton.removeAttr('disabled').html(this.marketoButtonTitle)
  }
}
