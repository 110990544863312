import { eventFromUnknownInput } from '@sentry/browser/esm/eventbuilder'
import { defaultStackParser } from '@sentry/browser/esm/stack-parsers'
import { fetch as fetchPolyfill } from 'whatwg-fetch'

const chiliTrackApi = {
  post: (path: string, data: Record<string, any>) =>
    fetchPolyfill(process.env.REACT_APP_TRACK_RESOURCE + path, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'post',
      body: JSON.stringify(data),
    }),
}

class ChiliSentry {
  dsn: string | undefined = ''
  version: string | undefined = ''
  env: string | undefined = ''

  init() {
    this.dsn = 'https://a12c625a4ae24dfdb7da7b5f52135e5c@o94797.ingest.sentry.io/5971989'
    this.version = process.env.SENTRY_RELEASE_COMMIT
    this.env = process.env.DEPLOY_ENV
  }

  captureException(error: unknown) {
    chiliTrackApi
      .post('/sentry/capture', {
        dsn: this.dsn,
        version: this.version,
        env: this.env,
        error: eventFromUnknownInput(defaultStackParser, error),
      })
      .catch(sentryError => {
        console.error('could not sent error report to tracking service', sentryError, { error })
      })
  }
}

export const chiliSentry = {
  sentry: new ChiliSentry(),
}
