import 'whatwg-fetch'
import { apiService } from './api/apiService'
import { chiliSentry } from './chiliTrack'

import { MarketingApi } from './MarketingApi'

import { findForms, waitUntilElementExists } from './utils'
import { MarketoForm } from './forms/MarketoForm'
import { PardotForm } from './forms/PardotForm'
import { HtmlForm } from './forms/HtmlForm'
import { HubspotForm } from './forms/HubspotForm'
import { Optional, Options } from './types'

console.log('Concierge version', process.env.APP_VERSION)
chiliSentry.sentry.init()

/* global document window */
const check = (
  options: Optional<Options, 'form'> = { domain: '', router: '' },
  handler: (options: Optional<Options, 'form'>) => void
) => {
  apiService(
    options.domain,
    `/marketing/inbound-router/enabled/${options.domain}/${options.router}`
  )
    .then(({ active }) => {
      if (!active) {
        console.log('chilipiper >> router is not active, trying to get dcp version')
      }
      try {
        handler({ ...options, isLegacyEnabled: active })
      } catch (err) {
        chiliSentry.sentry.captureException(err)
        console.log('chilipiper >> error on handling router status', err)
      }
    })
    .catch(err => {
      chiliSentry.sentry.captureException(err)
      console.log('chilipiper >> error on retriving router status', err)
    })
}

const createIntegration = (form: HTMLFormElement, options: Optional<Options, 'form'>) => {
  const formId = form.id ?? ''
  //@ts-ignore
  const isHubspotForm = typeof hbspt !== 'undefined' && formId.includes('hsForm')
  const isMarketo = formId.includes('mktoForm') && window.MktoForms2 !== undefined
  const isPardot = formId === 'pardot-form' || formId.includes('gform')
  if (isHubspotForm) return new HubspotForm({ ...options, form: form })
  if (isMarketo) return new MarketoForm({ form, formId, ...options })
  if (isPardot) return new PardotForm({ form, ...options })
  return new HtmlForm({ form, ...options })
}

const integrateForms = (options: Optional<Options, 'form'>, forms: NodeListOf<HTMLFormElement>) => {
  const integration = Array.from(forms).map(form => createIntegration(form, options))
  return integration
}

const showCalendarHubspotMobile = (options: Options) => {
  const hubspotData = JSON.parse(window.localStorage.getItem('hubspotData') || '')
  const hubspotForm = new HubspotForm({ ...options })
  hubspotForm.showCalendar(hubspotForm, hubspotData, options.domElement)
}

let IntegrationsRetry = 20
export const bookMeeting = (
  options: Optional<Options, 'form'>,
  forms: NodeListOf<HTMLFormElement>
) => {
  if (forms.length > 0) integrateForms(options, forms)
  else if (
    window.location.href.includes('hsFormGuid') &&
    window.location.href.includes('submissionGuid')
  ) {
    showCalendarHubspotMobile(options as Options)
  } else if (IntegrationsRetry > 0) {
    IntegrationsRetry -= 1
    setTimeout(() => {
      bookMeeting(options, forms)
    }, 1000)
  } else console.log('chilipiper >> could not integrate [form absent]')
}

const showCalendarAfterCheck =
  (form: HTMLFormElement | undefined, formData: Record<string, string>) =>
  (options: Optional<Options, 'form'>) => {
    const marketingApi = new MarketingApi({ form, ...options })
    marketingApi.showCalendar(marketingApi, formData, options.domElement)
  }

export const submitAndRoute = (
  options: Optional<Options, 'form'>,
  forms?: NodeListOf<HTMLFormElement>
) => {
  try {
    if (options.debug) console.log('chilipiper >> ', options)
    const form = options.form || (forms ? forms[0] : undefined)
    if (options.lead) {
      options.map = options.map || false
      const marketingApi = new MarketingApi({ form, ...options })
      marketingApi.showCalendar(marketingApi, options.lead, options.domElement)
    } else {
      options.map = true
      const formData = Array.from(form?.elements ?? []).reduce((acc: Record<string, string>, x) => {
        acc[(x as HTMLInputElement).name] = (x as HTMLInputElement).value
        return acc
      }, {})
      // for form on page https://verse.io/schedule-a-demo/ the field values are empty if check is done before mapping
      if (options.shouldCheck) {
        check(options, showCalendarAfterCheck(form, formData))
      } else {
        showCalendarAfterCheck(form, formData)(options)
      }
    }
  } catch (err) {
    chiliSentry.sentry.captureException(err)
  }
}

export const showCalendar = (
  options: Optional<Options, 'form'>,
  forms?: NodeListOf<HTMLFormElement>
) => {
  const dataPardot = JSON.parse(window.localStorage.getItem('dataPardot') || '{}')
  const form = options.form || (forms ? forms[0] : undefined)
  const marketingApi = new MarketingApi({ form, ...options })
  marketingApi.showCalendar(marketingApi, dataPardot, options.domElement)
}

const getQueryVariables = () => {
  const query = window.location.search.substring(1)
  const vars = query.split('&')
  const varObject: Record<string, string> = {}
  for (let i = 0; i < vars.length; i++) {
    const pair = vars[i].split('=')
    varObject[pair[0]] = decodeURIComponent(pair[1])
  }
  return varObject
}

const getQueryVariable = (variable: string) => {
  const variables = getQueryVariables()

  return variables[variable] || false
}

const queryVariableIncludes = (value: string) => {
  const variables = getQueryVariables()

  return Object.keys(variables).some(key => key.toLowerCase().includes(value.toLowerCase()))
}

export const getQuery = (options: Optional<Options, 'form'>) => {
  const checkBeforeRouting = options.checkInclusive ? queryVariableIncludes : getQueryVariable

  if (checkBeforeRouting(options.queryVariable || 'email')) {
    submitAndRoute({
      ...options,
      map: options.map !== undefined ? options.map : true,
      lead: {
        ...options.lead,
        ...getQueryVariables(),
      },
    })
  }
}

const executeWhenFormLoads = (
  handler: (options: Optional<Options, 'form'>, forms: NodeListOf<HTMLFormElement>) => void
) => {
  return (options: Optional<Options, 'form'>) => {
    return waitUntilElementExists(
      findForms(options),
      forms => handler({ ...options, shouldCheck: true }, forms),
      !!options.lead || !!options.form
    )
  }
}

export const ChiliPiper = {
  getQuery: (
    domain: string,
    router: string,
    options: Optional<Options, 'domain' | 'router' | 'form'> = {}
  ) => check({ domain, router, ...options }, getQuery),
  bookMeeting: (
    domain: string,
    router: string,
    options: Optional<Options, 'domain' | 'router' | 'form'> = {}
  ) => check({ domain, router, ...options }, executeWhenFormLoads(bookMeeting)),
  scheduling: (
    domain: string,
    router: string,
    options: Optional<Options, 'domain' | 'router' | 'form'> = {}
  ) => check({ domain, router, ...options }, executeWhenFormLoads(bookMeeting)),
  showCalendar: (
    domain: string,
    router: string,
    options: Optional<Options, 'domain' | 'router' | 'form'> = {}
  ) => check({ domain, router, ...options }, showCalendar),
  submit: (
    domain: string,
    router: string,
    options: Optional<Options, 'domain' | 'router' | 'form'> = {}
  ) => executeWhenFormLoads(submitAndRoute)({ domain, router, ...options }),
}

if (window.ChiliPiper) {
  Object.keys(window.ChiliPiper).forEach(key => {
    //@ts-ignore
    if (window.ChiliPiper[key].q && window.ChiliPiper[key].q.length) {
      //@ts-ignore
      window.ChiliPiper[key].q.forEach(param => ChiliPiper[key](...param))
    }
  })
}

;(global as any).ChiliPiper = ChiliPiper
