import { apiService, tenantService } from './api/apiService'
import { Options } from './types'
import { MarketingApi } from './MarketingApi'
import { stylesPopupCSS } from './stylesPopupCSS'
import { transformToLocalForConciergeDemoPage } from './helpers'
import { chiliSentry } from './chiliTrack'
import { convertResultToLegacyFormat } from './utils'
import { callRestApi } from './api/restApiService'

/* global document window navigator */
interface GlobalEvent {
  event: string
  callback: (event: any) => any
}

const URLRegex =
  /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&/=]*)/

const redirectIfValidUrl = (url: string) => {
  if (!URLRegex.test(url)) {
    console.error('Invalid redirect URL')
    return
  }

  window.location.href = url
}

export class PopUp {
  form: MarketingApi
  options: Options
  data: Record<string, any>
  domElement?: HTMLElement
  crmEvent?: any
  redirect: any = {}
  layout?: HTMLDivElement
  isFire?: boolean
  globalEvents: GlobalEvent[] = []

  constructor(
    formType: MarketingApi,
    data: Record<string, any>,
    domElement?: HTMLElement,
    crmEvent?: any
  ) {
    this.form = formType
    this.options = formType.options
    this.data = data
    this.domElement = domElement
    this.crmEvent = crmEvent
    this.redirect = {}

    //@ts-ignore
    if (window.addEventListener) {
      this.addAndRegisterListener('message', this.onMessage)
      //@ts-ignore
    } else if (window.attachEvent) {
      //@ts-ignore
      window.attachEvent('onmessage', this.onMessage, false)
    }
    this.buildLayout()
  }

  onMessage = (event: MessageEvent) => {
    if (event.data.message === 'CP_BACK_PRESSED') {
      if (event.data.action === 'CLOSE_IFRAME') {
        this.close()
      } else if (event.data.action === 'REDIRECT') {
        redirectIfValidUrl(event.data.url)
      }
    }
  }

  handleError(err: any) {
    chiliSentry.sentry.captureException(err)
    this.close()
    this.form.onError(err)
  }

  show() {
    const post = {
      form: this.data,
      options: this.form.formOptions,
      event: this.crmEvent,
    }

    callRestApi(
      this.options.domain,
      this.options.router,
      post,
      this.showPopUp.bind(this),
      this.handleError.bind(this),
      this.options.isLegacyEnabled
    )
  }

  close() {
    if (this.layout) {
      this.getParentNode().removeChild(this.layout)
      this.layout = undefined
    }
  }

  isDomElementSupplied() {
    if (typeof this.domElement === 'string') {
      return !!document.querySelector(this.domElement)
    } else if (this.domElement) {
      return true
    }
    return false
  }

  getParentNode() {
    if (this.domElement) {
      if (typeof this.domElement === 'string') {
        const element = document.querySelector(this.domElement)
        if (element) {
          return element
        } else {
          return document.body
        }
      } else {
        return this.domElement
      }
    }
    return document.body
  }

  isLightning(link: string) {
    return link.includes('lightning_')
  }

  buildLayout() {
    if (!this.layout) {
      this.loadCss()
      const div = document.createElement('div')
      div.className = `chilipiper-popup ${this.isDomElementSupplied() ? 'in-custom-element' : ''}`
      div.innerHTML = `
                <div class='chilipiper-close'> </div>
                <div class='chilipiper-popup-loading'> </div>
            `
      this.getParentNode().appendChild(div)
      this.layout = div
    }
  }

  setIframeSize = ({ width, height }: { width: string; height: string }) => {
    if (!this.layout) {
      return
    }

    const popup = this.layout.querySelector('.chilipiper-popup-window') as HTMLElement
    if (!this.isFire) {
      const maxWidth = window.innerWidth - 20
      const maxHeight = window.innerHeight - 20
      popup.style.borderRadius = '8px'
      popup.style.background = 'transparent'
      popup.style.width = `${Math.min(Number(width), maxWidth)}px`
      const footerSize = 41
      popup.style.height = `${Math.min(Number(height) + footerSize, maxHeight)}px`
    }
  }

  resize() {
    if (this.layout) {
      const popup = this.layout.querySelector('.chilipiper-popup-window') as HTMLElement
      if (!this.isFire) {
        popup.style.height = `${window.innerHeight - 20}px`
        popup.style.width = `${window.innerWidth - 20}px`
      } else {
        this.layout.className = `${this.layout.className} fire`
      }
    }
  }

  onlineCalendarLink(data: any) {
    const title = encodeURI(this.options.title || '')
    const titleStyle = encodeURI(this.options.titleStyle || '').replace(/#/, '')
    const url = data.realtimeUrl || data.url

    return transformToLocalForConciergeDemoPage(`${url}&title=${title}&titleStyle=${titleStyle}`)
  }

  showAsRedirect(data: any) {
    this.close()
    let processRedirect
    let closeRedirect
    let redirectTimeout
    if (this.options.mobileRedirectLink) processRedirect = this.options.mobileRedirectLink
    else if (this.options.dynamicRedirectLink) processRedirect = this.options.dynamicRedirectLink
    else if (data.redirect && data.redirect.enabled) {
      if (data.redirect.link && data.redirect.link.length > 0) {
        processRedirect = data.redirect.link
        redirectTimeout = data.redirect.timeout
      }
      if (data.redirect.closeLink && data.redirect.closeLink.length > 0) {
        closeRedirect = data.redirect.closeLink
      }
    }

    if (!closeRedirect) closeRedirect = processRedirect

    const processRedirectParam = processRedirect
      ? `&processRedirect=${encodeURIComponent(processRedirect)}`
      : ''
    const closeRedirectParam = closeRedirect
      ? `&closeRedirect=${encodeURIComponent(closeRedirect)}`
      : ''
    const timeoutRedirectParam = redirectTimeout
      ? `&redirectTimeout=${encodeURIComponent(redirectTimeout)}`
      : ''
    window.location.href = `${this.onlineCalendarLink(
      data
    )}${processRedirectParam}${closeRedirectParam}${timeoutRedirectParam}`
  }

  showPopUp(data: any) {
    let hasBooked = false

    const handleFocusIframe = () => {
      const iframe = this.layout?.querySelector('iframe')
      return iframe?.focus()
    }

    const close = () => {
      this.removeGlobalListeners()
      this.close()
      this.form.onClose(hasBooked)
      this.layout?.querySelector('iframe')?.removeEventListener('load', handleFocusIframe)
    }

    const onResize = () => this.resize()

    const onKeyDown = (event: KeyboardEvent) => {
      if (event.code === 'Escape' || event.key === 'Escape' || event.keyCode === 27) close()
    }

    const onClick = () => close()

    const onMessage = (event: MessageEvent) => {
      if (event.data.action === 'booked') {
        hasBooked = true
      } else if (event.data.action === 'esc' && this.options.closeOnOutside) close()
      else if (event.data.action === 'close') close()
      else if (event.data.action === 'phoneClose') this.close()
      else if (event.data.action === 'REDIRECT') redirectIfValidUrl(event.data.url)
      else if (event.data.action === 'booking-confirmed') this.form.onSuccess(event.data.args)
      else if (event.data.action === 'SetIframeSize') this.setIframeSize(event.data.args)
    }

    if (!data.error && this.options.onRouted) {
      this.options.onRouted({
        router: data,
        data: this.data,
      })
    }

    if (data.error) {
      console.log('chilipiper >> error', data)
      this.handleError(data.error)
    } else if (!data.url && !data.realtimeUrl) {
      const NoRulesMatch = 8003
      const NoActiveOnlineQueues = 8007
      if (
        [NoRulesMatch, NoActiveOnlineQueues].includes(data.code) &&
        (data.redirect || {}).disqualifiedLink
      ) {
        let link = data.redirect.disqualifiedLink
        if (!link.startsWith('http')) link = `http://${link}`
        window.location.href = link
      } else {
        this.handleError(data.message)
      }
    } else {
      this.redirect = data.redirect
      this.loadCss()
      const div = this.layout
      let link = data.url
      this.isFire = !!data.fire
      if (div) {
        if (!data.fire) {
          link = this.onlineCalendarLink(data)
          if (this.options.injectRootCss) link = `${link}/&domain=${this.options.domain}`
          link += '&fromSnippet=true'
        }
        const removeLoadingScript = document.createElement('script')
        removeLoadingScript.innerHTML = `
            document.getElementById('chilipiper-frame').onload = function () {
              var image = document.getElementById('loadImg')
              if (image) {
                image.style.display = 'none'
              }
              document.getElementById('chilipiper-frame').contentWindow.focus()
            }
          `
        div.innerHTML =
          this.isLightning(link) || data.fire
            ? `
                  <div class='chilipiper-popup-window fire' style='background: transparent'>
                      <iframe class='chilipiper-frame' src='${link}' frameBorder='0' />
                  </div>
              `
            : `
                  <div class='chilipiper-popup-window' style='max-width: 560px; max-height: 840px'>
                      <div id='loadImg' class='routing-loader'>
                        <div data-test-id="DotsLoader"
                          class="sc-bdvvtL sc-gsDKAQ sc-dkPtRN sc-nVkyK bgufkB bbwWsg cgynxk jJfaIE">
                          <div data-chili-css="loader-dot" class="dots-loader dot1"></div>
                          <div data-chili-css="loader-dot" class="dots-loader dot2"></div>
                          <div data-chili-css="loader-dot" class="dots-loader dot3"></div>
                        </div>
                      </div>
                      <iframe id='chilipiper-frame' class='chilipiper-frame' src='${link}' frameBorder='0' aria-description='Page with slots'/>
                  </div>
              `

        document.body.appendChild(removeLoadingScript)
      }
      this.resize()

      if (this.options.closeOnOutside) {
        this.addAndRegisterListener('keydown', onKeyDown)
        this.addAndRegisterListener('click', onClick)
      }
      this.addAndRegisterListener('resize', onResize)
      this.addAndRegisterListener('message', onMessage)
      this.layout?.querySelector('iframe')?.addEventListener('load', handleFocusIframe)
    }
  }

  removeGlobalListeners = () => {
    this.globalEvents.forEach((l: any) => window.removeEventListener(l.event, l.callback))
    this.globalEvents = []
  }

  addAndRegisterListener = (event: string, callback: (event: any) => any) => {
    this.globalEvents.push({ event, callback })
    if (window.addEventListener) {
      window.addEventListener(event, callback)
    }
  }

  loadCss() {
    const cssId = 'chilipiper-css'
    if (!document.getElementById(cssId)) {
      const head = document.getElementsByTagName('head')[0]
      const style = document.createElement('style')
      style.textContent = stylesPopupCSS
      head.appendChild(style)
    }
  }
}
