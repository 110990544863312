export const stylesPopupCSS = `
.chilipiper-popup {
     background: rgba(0, 0, 0, 0.8);
     top: 0;
     left: 0;
     right: 0;
     bottom: 0;
     position: fixed;
     z-index: 999999;
     display: flex;
     flex-direction: row;
     justify-content: center;
     align-items: center;
}
.chilipiper-popup.fire {
  background: transparent;
}
 .chilipiper-popup.in-custom-element {
     position: absolute;
}
 .chilipiper-popup .chilipiper-close {
     top: 10px;
     right: 10px;
     position: absolute;
     width: 15px;
     height: 15px;
     cursor: pointer;
     overflow: hidden;
     text-indent: 1000px;
}
.chilipiper-popup .chilipiper-popup-window:not(.fire) {
    width: 441px;
    height: 750px;
    border-radius: 8px;
    background: #fff;
}
.chilipiper-popup-window.fire {
    width: 100vw;
    height: 100vh;
    background: transparent;
}
 .chilipiper-popup .chilipiper-popup-window .routing-loader {
     position: absolute;
     width: inherit;
     max-width: inherit;
     height: 100%;
   display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -50px;
}
 .chilipiper-popup .chilipiper-popup-window .routing-loader .routing-loader-body {
     display: flex;
     flex-direction: column;
     align-items: center;
}
 .chilipiper-popup .chilipiper-popup-window .routing-loader .routing-loader-body span {
     margin-bottom: 40px;
     font-weight: 500;
     font-size: 18px;
     color: #808284;
}
 .chilipiper-popup .chilipiper-frame {
     overflow: hidden;
     border-radius: 8px;
     height: 100%;
     width: 1px;
     min-width: 100%;
}
.chilipiper-popup.fire .chilipiper-frame {
  overflow: hidden;
  border-radius: 0px;
  height: 100%;
  width: 1px;
  min-width: 100%;
}
 .chilipiper-popup .fabe-first-loading-label {
     margin-top: 30px;
     margin-left: 30px;
     display: block;
}

.chilipiper-popup .chilipiper-popup-loading {
  width: 50px;
  height: 50px;
  background-image: url("https://js.${process.env.CHILIPIPER_API_DOMAIN}/images/loading.gif");
  background-size: 100%;
}

@keyframes bounce {
  0%,
  80%,
  100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
}

[data-chili-css="loader-dot"] {
  width: 1rem;
  height: 1rem;
  background: rgba(38,52,64,0.32);
}

.dot1,
.dot2,
.dot3 {
  border-radius: 100%;
  display: inline-block;
  animation: bounce 1.4s infinite ease-in-out both;
}

.dot1 {
  animation-delay: -0.32s;
}

.dot2 {
  animation-delay: -0.16s;
}
`
