const getQsParams = () => {
  const str = window.location.hash.split('?')[1] || window.location.search
  const params: Record<string, unknown> = {}

  str.replace(new RegExp('([^?=&]+)(=([^&]*))?', 'g'), ($0, $1, $2, $3) => {
    return (params[$1] = $3)
  })
  return params
}

/* global document window */
export const transformToLocalForConciergeDemoPage = (url: string) => {
  const isConciergeDemoPage = document.body.getAttribute('data-page-id') === 'concierge-demo'

  if (!isConciergeDemoPage) {
    return url
  }

  const urlLink = new URL(url)
  const domain = urlLink.host.substring(0, urlLink.host.indexOf('.'))

  const qs = getQsParams()

  let properUrlForLocalAndCloudrun = `${window.location.origin}${urlLink.pathname}${urlLink.search}&domain=${domain}`

  if (qs.withPhone) {
    properUrlForLocalAndCloudrun += '&withPhone=true'
  }

  if (qs.withVideo) {
    properUrlForLocalAndCloudrun += '&withVideo=true'
  }

  return properUrlForLocalAndCloudrun
}
