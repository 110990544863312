import { apiService } from './api/apiService'
import { chiliSentry } from './chiliTrack'
import { Options } from './types'

export class Redirect {
  options: Options
  onError = (err: unknown) => {
    chiliSentry.sentry.captureException(err)
  }
  redirect: any = {}
  constructor(api: any) {
    this.options = api.options
    const setRedirect = (router: any) => {
      if (router.error) {
        this.onError(router.error)
      }
      this.redirect = router.redirect
    }

    apiService(
      this.options.domain,
      `/marketing/inbound-router/redirect/${this.options.domain}/${this.options.router}`
    )
      .then(setRedirect)
      .catch(error => {
        api.onError(error)
      })
  }

  proceedRedirect() {
    if ((this.redirect && this.redirect.enabled) || this.options.dynamicRedirectLink) {
      const timeout = (this.redirect || {}).timeout || 5
      setTimeout(() => {
        const link = this.options.dynamicRedirectLink
          ? this.options.dynamicRedirectLink
          : this.redirect.link
        this.navigate(link)
      }, timeout * 1000)
    }
  }

  navigate(link: string) {
    if (link && link.length > 0) {
      if (!link.startsWith('http')) link = `http://${link}`
      window.location.href = link
    }
  }

  proceedCloseRedirect() {
    if (this.redirect) {
      const strategy = this.redirect.closeStrategy
      if (strategy === 'Landing') {
        const link = this.options.dynamicRedirectLink
          ? this.options.dynamicRedirectLink
          : this.redirect.link
        this.navigate(link)
      } else if (strategy === 'Uri') {
        const link = this.options.dynamicRedirectLink
          ? this.options.dynamicRedirectLink
          : this.redirect.closeLink
        this.navigate(link)
      }
    }
  }
}
