import { fetch as fetchPolyfill } from 'whatwg-fetch'
import { api } from './endpoint'
import { Optional, Options, FireRestAPIResult } from './types'

export function convertResultToLegacyFormat(data: FireRestAPIResult, domElement?: boolean) {
  const url = new URL(data.routingLink)
  if (!domElement) {
    url.searchParams.append('concierge', 'true')
  }
  return {
    url: url.toString(),
    fire: true,
  }
}

export function waitUntilElementExists(
  selector: string,
  callback: (elements: NodeListOf<HTMLFormElement>) => any,
  bypass = false
) {
  const el = document.querySelectorAll<HTMLFormElement>(selector)
  if (el.length) {
    console.debug(`form found: ${selector}`)
    return callback(el)
  } else if (bypass) {
    return callback(el)
  }

  setTimeout(() => {
    waitUntilElementExists(selector, callback)
  }, 500)
}

export function findForms(options: Optional<Options, 'form'>) {
  if (options.query) {
    return options.query
  }

  if (options.formId) {
    return `#${options.formId}`
  }
  return 'form'
}

export function noop() {}

export function wait(delay: number) {
  return new Promise(resolve => setTimeout(resolve, delay))
}

export async function fetchRetry(
  url: string,
  delay: number,
  tries: number,
  callback: () => void,
  handleError?: (error: any) => void
) {
  async function onError(err: any) {
    const triesLeft = tries - 1
    if (!triesLeft) {
      handleError?.(err)
      return
    }
    await wait(delay)
    return fetchRetry(url, delay, triesLeft, callback, handleError)
  }
  try {
    const response = await fetchPolyfill(api(url))
    const result: { canRoute: boolean } = await response.json()
    if (result.canRoute) {
      callback()
    } else {
      onError('Contact not created in Hubspot CRM')
    }
  } catch (error) {
    onError(error)
  }
}
